<div class="adxad-main-container">
  <adxad-tabs-nav>
    <adxad-tab-link
      *ngFor="let tab of tabs"
      [routerLink]="tab.link"
      routerLinkActive="active"
    >
      {{tab.name}}
    </adxad-tab-link>
  </adxad-tabs-nav>

  <router-outlet></router-outlet>
</div>
