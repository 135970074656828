import { Component, DestroyRef, inject, OnInit, ViewChild } from '@angular/core';
import {
  CreativeModeration,
  CreativeModerationData,
  FilterChanges,
  FiltersConfig,
  FilterServiceConfigMeta,
  GridRequest,
  ModerationGridType
} from '../../../../core/interface';
import { DspService } from '../../../../core/services/dsp.service';
import { CreativesService } from '../../../trade-desk/modules/creatives/creatives.service';
import { ModerationService } from '../../moderation.service';
import { AdxadAlerts } from '../../../../ui/modules/alerts/components/alerts/alerts.component';
import { AdxadSidebarModal } from '../../../../ui/modules/sidebar-modal/sidebar-modal.service';
import { ModerationCreativeViewComponent } from '../moderation-creative-view/moderation-creative-view.component';
import { PaginatorComponent } from '../../../../ui/modules/paginator/paginator.component';
import { FiltersService } from '../../../../core/services/filters.service';
import { AdxadModal } from '../../../../ui/modules/modal/modal.service';
import { CodeModalComponent } from '../../../../gui/components/code-modal/code-modal.component';
import { SidebarFilterComponent } from '../../../../dynamic-modules/dynamic-filter/components/sidebar-filter/sidebar-filter.component';
import { Observable, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ProjectViewComponent } from '../../../trade-desk/modules/projects/components/project-view/project-view.component';
import { CampaignViewComponent } from '../../../trade-desk/modules/campaigns/components/campaign-view/campaign-view.component';
import dayjs from 'dayjs';
import { DATE_FORMAT, Status } from '../../../../core/configs';
import { ActivatedRoute } from '@angular/router';
import { ModerationNeedInfoModalComponent } from '../moderation-need-info-modal/moderation-need-info-modal.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-moderation-grid',
  templateUrl: './moderation-grid.component.html',
  styleUrls: ['./moderation-grid.component.scss'],
  providers: [FiltersService]
})
export class ModerationGridComponent implements OnInit {
  @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent;

  public gridType: ModerationGridType = 'pending';
  public isLoading = false;
  public creatives: CreativeModeration[] = [];

  public multiSelect = {
    checkAll: false,
    indeterminate: false,
    checked: []
  };

  public dict = {
    teams: [],
    users: [],
    project: [],
    campaign: [],
    advertFormat: [],
    mediaType: []
  };

  public readonly Status = Status;

  private destroyRef = inject(DestroyRef);
  private requestGrid$ = new Subject<GridRequest>();
  private loadGrid$: Observable<CreativeModerationData> = this.requestGrid$.pipe(
    tap(() => {
      this.isLoading = true;
      this.resetGrid();
    }),
    switchMap(data => this.moderationService.getModerationGrid(data, this.gridType))
  );

  constructor(
    public filter: FiltersService,
    private dspService: DspService,
    private creativesService: CreativesService,
    private moderationService: ModerationService,
    private alerts: AdxadAlerts,
    private sidebarModal: AdxadSidebarModal,
    private modal: AdxadModal,
    private route: ActivatedRoute
  ) {
    this.gridType = this.route.snapshot.data['type'];
  }

  ngOnInit(): void {
    this.subscribeFilter();
    this.paginator.setLimitList([12, 20, 50, 100, 500, 1000, 2000, 3000]);
    this.paginator.init();

    this.loadGrid$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (result: CreativeModerationData) => {
        this.isLoading = false;
        this.setGrid(result);
      },
      error: () => (this.isLoading = false)
    });

    this.loadFilters();
  }

  /**
   * Load filters list
   * Set today in calendar
   */
  loadFilters(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    this.dspService
      .getFilters('moderation')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ({ data, status }: FiltersConfig) => {
          this.isLoading = false;

          if (status === 'OK') {
            const meta = { ignoreSessionStorageDates: true } as FilterServiceConfigMeta;
            this.filter.init({ data, meta });

            if (this.gridType !== 'needInfo' && this.gridType !== 'pending') {
              const today = dayjs().format(DATE_FORMAT);
              this.filter.changeCalendarDates({ from: today, to: today });
            }

            this.loadGrid();
          }
        },
        error: () => (this.isLoading = false)
      });
  }

  /**
   * Subscribe on filter changes
   */
  subscribeFilter(): void {
    this.filter.changeFilter.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data: FilterChanges) => {
      if (data && data.submit) {
        this.paginator.resetPage();
        this.loadGrid();
      }
    });
  }

  /**
   * Clear paginator,
   * not created & not found flags set false
   */
  resetGrid(): void {
    this.creatives = [];
    this.paginator.clearList();
  }

  /**
   * Open sidebar filter
   */
  openFilter(): void {
    this.sidebarModal.open(SidebarFilterComponent, { data: this.filter });
  }

  /**
   * Apply filter by btn
   * Reset current page
   */
  applyFilter(): void {
    this.paginator.resetPage();
    this.loadGrid();
  }

  /**
   * Reset current page
   * Clear filter values
   * Reload grid
   */
  clearFilters(): void {
    this.paginator.resetPage();
    this.filter.clearValues();
    this.loadGrid();
  }

  /**
   * Set requestGrid$ to load grid
   */
  loadGrid(): void {
    const request = {
      limit: this.paginator && this.paginator.limit,
      page: this.paginator && this.paginator.page,
      filter: this.filter.request
    } as GridRequest;

    this.requestGrid$.next(request);
  }

  /**
   * Set creatives grid
   * @param {CreativeModerationData} result
   */
  private setGrid(result: CreativeModerationData): void {
    if (!result || result.status !== 'OK') {
      this.alerts.error('Oops something went wrong', 3000);
      return;
    }

    if (result.data && result.data.length) {
      this.creatives = result.data;
      this.paginator.createList(result.meta.total);
    }
  }

  /**
   * @return {boolean} flag to disable multi actions
   */
  get isDisableMultiActions(): boolean {
    return !this.multiSelect.checked.length;
  }

  /**
   * Add/remove creative in array for multi edit
   *
   * @param creative
   * @param checked
   */
  toggleCreative(creative: CreativeModeration, checked: boolean): void {
    const creatives = this.multiSelect.checked;
    checked ? creatives.push(creative.id) : creatives.splice(creatives.indexOf(creative.id), 1);
  }

  /**
   * Add/remove all creatives in multiselect
   */
  toggleAll(): void {
    this.multiSelect.checked = this.multiSelect.checkAll ? [] : this.creatives.map(x => x.id);
    this.multiSelect.checkAll = !this.multiSelect.checkAll;
  }

  /**
   * @param id
   * @return checked creative
   */
  isChecked(id: string): boolean {
    return this.multiSelect.checked.indexOf(id) !== -1;
  }

  /**
   * Open set need info modal
   * If is success, reload grid
   */
  setNeedInfoStatus(): void {
    const ids = this.multiSelect.checked;

    if (!ids.length) {
      this.alerts.warning('need select one or more creatives');
      return;
    }

    this.modal
      .open(ModerationNeedInfoModalComponent, {
        width: '588px',
        data: { ids }
      })
      .afterClosed.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        if (value && value.submit) {
          this.multiSelect.checked = [];
          this.loadGrid();
        }
      });
  }

  /**
   * Approve/reject creatives
   *
   * @param status
   * @param ids
   */
  multiChangeStatus(status: number, ids?: string[]): void {
    if (ids === undefined) {
      ids = this.multiSelect.checked;
    }

    const request = {
      status,
      ids: ids
    };
    this.isLoading = true;

    this.creativesService
      .changeCreativesStatus(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.isLoading = false;
          this.multiSelect.checked = [];
          this.loadGrid();
        },
        error: () => (this.isLoading = false)
      });
  }

  /**
   * Open detail creative in sidebar modal
   *
   * @param creative
   */
  openCreative(creative): void {
    this.sidebarModal.open(ModerationCreativeViewComponent, {
      data: {
        id: creative.id
      }
    });
  }

  /**
   * Open iframe model
   *
   * @param {CreativeModeration} creative
   */
  openIframeCode(creative: CreativeModeration): void {
    if (!creative || !creative.html) {
      return;
    }

    this.modal.open(CodeModalComponent, {
      width: '588px',
      data: {
        value: creative.html,
        type: 'html'
      }
    });
  }

  /**
   * Open project detail in modal
   *
   * @param {string} id
   */
  openProject(id: string): void {
    if (!id) {
      this.alerts.error('Oops something went wrong', 3000);
      return;
    }

    this.sidebarModal.open(ProjectViewComponent, {
      data: {
        id: id
      }
    });
  }

  /**
   * Open campaign detail in modal
   *
   * @param {string} id
   */
  openCampaign(id: string): void {
    if (!id) {
      this.alerts.error('Oops something went wrong', 3000);
      return;
    }

    this.sidebarModal.open(CampaignViewComponent, {
      data: {
        id: id
      }
    });
  }
}
