import { Component } from '@angular/core';
import { MODERATION_ROUTE } from '../../../core/routes';

@Component({
  selector: 'app-moderation-layout',
  templateUrl: './moderation-layout.component.html',
  styleUrls: ['./moderation-layout.component.scss']
})
export class ModerationLayoutComponent {
  tabs = [
    {
      name: 'Pending',
      link: './' + MODERATION_ROUTE.pending
    },
    {
      name: 'Checking',
      link: './' + MODERATION_ROUTE.checking
    },
    {
      name: 'Approved',
      link: './' + MODERATION_ROUTE.approved
    },
    {
      name: 'Rejected',
      link: './' + MODERATION_ROUTE.rejected
    },
    {
      name: 'Need info',
      link: './' + MODERATION_ROUTE.needInfo
    }
  ];
}
