import { Component, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core';
import { CreativeDetail, CreativeDetailResponse } from '../../../../core/interface';
import { AdxadSidebarModalConfig } from '../../../../ui/modules/sidebar-modal/sidebar-modal-config';
import { AdxadSidebarModalRef } from '../../../../ui/modules/sidebar-modal/sidebar-modal-ref';
import { CreativesService } from '../../../trade-desk/modules/creatives/creatives.service';
import { AdxadAlerts } from '../../../../ui/modules/alerts/components/alerts/alerts.component';
import { QueryParamsService } from '../../../../core/services/query-params.service';
import { ModerationService } from '../../moderation.service';
import { Status } from '../../../../core/configs';
import { CampaignViewComponent } from '../../../trade-desk/modules/campaigns/components/campaign-view/campaign-view.component';
import { GlobicaService } from '../../../../core/services/globica.service';
import { AdxadSidebarModal } from '../../../../ui/modules/sidebar-modal/sidebar-modal.service';
import { BreadcrumbsService } from '../../../../core/services/breadcrumbs.service';
import { UserViewComponent } from '../../../users/modules/users/components/user-view/user-view.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-moderation-creative-view',
  templateUrl: './moderation-creative-view.component.html',
  styleUrls: ['./moderation-creative-view.component.scss']
})
export class ModerationCreativeViewComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public isLoadingStatus = false;
  public creative: CreativeDetail;
  public showTab = 1;

  private destroyRef = inject(DestroyRef);

  constructor(
    private config: AdxadSidebarModalConfig,
    private sidebarModalRef: AdxadSidebarModalRef,
    private creativesService: CreativesService,
    private moderationService: ModerationService,
    private alerts: AdxadAlerts,
    private queryParamsService: QueryParamsService,
    private globica: GlobicaService,
    private sidebarModal: AdxadSidebarModal,
    public breadcrumbs: BreadcrumbsService
  ) {}

  ngOnInit(): void {
    if (!this.config || !this.config.data.id) {
      this.close();
    }

    this.queryParamsService.add('sm_moderationCreativeView', this.config.data.id);
    this.loadCreative();
  }

  /**
   * @return {boolean} show preview flag
   */
  get isPreview(): boolean {
    return this.creative.mediaType !== 'pops' && this.creative.mediaType !== 'interstitial';
  }

  /**
   * @return {boolean} is iframe creative flag
   */
  get isIframe(): boolean {
    return this.creative.mediaType === 'html';
  }

  /**
   * @return {string} clickUrl if is pops or interstitial? get it from creative data
   */
  get clickUrl(): string {
    return this.creative.mediaType === 'pops' || this.creative.mediaType === 'interstitial'
      ? this.creative.creative.clickUrl
      : this.creative.clickUrl;
  }

  /**
   * @return {boolean} is video creative flag
   */
  get isVideo(): boolean {
    return this.creative.mediaType === 'video';
  }

  /**
   * @return {boolean} is vast creative flag
   */
  get isVast(): boolean {
    return this.creative.mediaType === 'vast';
  }

  /**
   * Load detail creative info
   * If error -> close sidebar modal
   */
  loadCreative(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    this.moderationService
      .getCreativeOnModeration({ id: this.config.data.id })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result: CreativeDetailResponse) => {
          this.isLoading = false;
          this.creative = result.data as CreativeDetail;
        },
        error: () => this.close()
      });
  }

  /**
   * Set approve, pending or reject status
   *
   * @param {number} status
   */
  changeStatus(status: number): void {
    if (status === undefined || this.isLoadingStatus) {
      return;
    }

    this.isLoadingStatus = true;
    const data = {
      ids: [this.config.data.id],
      status: status
    };

    this.creativesService
      .changeCreativesStatus(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          this.isLoadingStatus = false;

          if (result.status === 'OK') {
            this.creative.status = result.data[0].status;
            const message =
              'The creative is ' +
              (this.creative.status === Status.active
                ? 'approved'
                : this.creative.status === Status.pending
                  ? 'set as pending'
                  : 'rejected');
            this.alerts.success(message, 3000);
          }
        },
        error: () => (this.isLoadingStatus = false)
      });
  }

  /**
   * Close creative view sidebar
   */
  close(): void {
    this.sidebarModalRef.close();
  }

  /**
   * Close sidebar modal & open campaign view component
   *
   * @param {string} id of campaign
   */
  openCampaignDetails(id: string): void {
    this.globica.trackEventGoals('buttonModerationCreativeDetailsCampaign_click', {
      campaign_id: id,
      creative_id: this.creative.id
    });

    this.close();

    this.breadcrumbs.add({
      component: ModerationCreativeViewComponent,
      id: this.creative.id
    });

    this.sidebarModal.open(CampaignViewComponent, {
      data: {
        id: id
      }
    });
  }

  /**
   * Close sidebar modal & open owner view component
   */
  openUserView(): void {
    if (!this.creative.manager?.id) {
      return;
    }

    this.close();
    this.breadcrumbs.add({
      component: ModerationCreativeViewComponent,
      id: this.creative.id
    });

    this.sidebarModal.open(UserViewComponent, {
      data: {
        id: this.creative.manager.id
      }
    });
  }

  ngOnDestroy(): void {
    this.queryParamsService.remove('sm_moderationCreativeView');
  }
}
