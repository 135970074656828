import { Injectable } from '@angular/core';
import { Config, CreativeDetailResponse, CreativeModerationData } from '../../core/interface';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { DspService, Request } from '../../core/services/dsp.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModerationService {
  private config: Config = environment;

  constructor(
    private http: HttpClient,
    private dspService: DspService
  ) {}

  /**
   * Get grid of creatives on moderation
   *
   * @param {Request} request
   * @param {string} grid
   */
  getModerationGrid(request: Request, grid: string): Observable<CreativeModerationData> {
    return this.http.get<CreativeModerationData>(`${this.config.dsp_endpoint}/creative/moderation/${grid}`, {
      params: this.dspService.prepareRequest(request)
    });
  }

  /**
   * Get creative on moderation detail info
   * todo fix method
   *
   * @param {request} request
   */
  getCreativeOnModeration(request: Request): Observable<CreativeDetailResponse> {
    return this.http.get<CreativeDetailResponse>(`${this.config.dsp_endpoint}/creative/get`, {
      params: this.dspService.prepareRequest(request)
    });
  }
}
