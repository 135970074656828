import { Routes } from '@angular/router';
import { ModerationLayoutComponent } from './layout/moderation-layout.component';
import { MODERATION_ROUTE } from '../../core/routes';
import { ModerationGridComponent } from './components/moderation-grid/moderation-grid.component';

export const routes: Routes = [
  {
    path: '',
    component: ModerationLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: MODERATION_ROUTE.pending,
        pathMatch: 'full'
      },
      {
        path: MODERATION_ROUTE.pending,
        component: ModerationGridComponent,
        title: 'Moderation • Pending',
        data: {
          type: 'pending'
        }
      },
      {
        path: MODERATION_ROUTE.rejected,
        component: ModerationGridComponent,
        title: 'Moderation • Rejected',
        data: {
          type: 'rejected'
        }
      },
      {
        path: MODERATION_ROUTE.approved,
        component: ModerationGridComponent,
        title: 'Moderation • Approved',
        data: {
          type: 'approved'
        }
      },
      {
        path: MODERATION_ROUTE.checking,
        component: ModerationGridComponent,
        title: 'Moderation • Checking',
        data: {
          type: 'secure'
        }
      },
      {
        path: MODERATION_ROUTE.needInfo,
        component: ModerationGridComponent,
        title: 'Moderation • Need info',
        data: {
          type: 'needInfo'
        }
      }
    ]
  }
];
