import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { AdxadModalRef } from '../../../../ui/modules/modal/modal-ref';
import { FormControl } from '@angular/forms';
import { AdxadModalConfig } from '../../../../ui/modules/modal/modal-config';
import { AdxadAlerts } from '../../../../ui/modules/alerts/components/alerts/alerts.component';
import { CreativesService } from '../../../trade-desk/modules/creatives/creatives.service';
import { Status } from 'src/app/core/configs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-moderation-need-info-modal',
  templateUrl: './moderation-need-info-modal.component.html',
  styleUrls: ['./moderation-need-info-modal.component.scss']
})
export class ModerationNeedInfoModalComponent implements OnInit {
  public comment = new FormControl('');
  public isLoadingSubmit = false;

  private destroyRef = inject(DestroyRef);

  constructor(
    private modalRef: AdxadModalRef,
    private config: AdxadModalConfig,
    private alerts: AdxadAlerts,
    private creativesService: CreativesService
  ) {}

  ngOnInit(): void {
    if (!this.config.data?.ids?.length) {
      this.alerts.error('need select one or more creatives');
      this.close();
    }
  }

  /**
   * Close modal
   * if submit == true, grid will reload
   *
   * @param {boolean} submit
   * @param {string} data
   */
  close(submit?: boolean, data?: string): void {
    this.modalRef.close({ submit: submit });
  }

  /**
   * Set need info status on selected creatives
   */
  submit(): void {
    if (this.isLoadingSubmit) {
      return;
    }

    this.isLoadingSubmit = true;

    const request = {
      status: Status.needInfo,
      ids: this.config.data.ids,
      comment: this.comment.value
    };

    this.creativesService
      .changeCreativesStatus(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.alerts.success('Success', 3000);
          this.close(true);
        },
        error: () => (this.isLoadingSubmit = false)
      });
  }
}
