import { NgModule } from '@angular/core';
import { ModerationLayoutComponent } from './layout/moderation-layout.component';
import { GuiModule } from '../../gui/gui.module';
import { RouterModule } from '@angular/router';
import { ModerationGridComponent } from './components/moderation-grid/moderation-grid.component';
import { ModerationCreativeViewComponent } from './components/moderation-creative-view/moderation-creative-view.component';
import { DynamicGridModule } from '../../dynamic-modules/dynamic-grid/dynamic-grid.module';
import { DynamicFilterModule } from '../../dynamic-modules/dynamic-filter/dynamic-filter.module';
import { ModerationNeedInfoModalComponent } from './components/moderation-need-info-modal/moderation-need-info-modal.component';
import { routes } from './moderation.routes';

const ENTRY_MODULES = [GuiModule, DynamicGridModule, DynamicFilterModule];

const ENTRY_COMPONENTS = [ModerationLayoutComponent, ModerationGridComponent, ModerationCreativeViewComponent];

@NgModule({
  imports: [...ENTRY_MODULES, RouterModule.forChild(routes)],
  declarations: [...ENTRY_COMPONENTS, ModerationNeedInfoModalComponent]
})
export class ModerationModule {}
