<section class="row adxad-toolbar">
  <div class="col-auto adxad-actions">
    <button
      adxadStrokedButton
      *ngIf="gridType !== 'rejected'"
      (click)="multiChangeStatus(Status.rejected)"
      icon="block"
      iconHoverColor="error"
      [disabled]="isDisableMultiActions"
      class="action-btn"
    >
      Reject
    </button>

    <button
      adxadStrokedButton
      *ngIf="gridType === 'approved' || gridType === 'rejected'"
      (click)="multiChangeStatus(Status.pending)"
      icon="refresh"
      iconHoverColor="warning"
      [disabled]="isDisableMultiActions"
      class="action-btn"
    >
      Pending
    </button>

    <button
      adxadStrokedButton
      *ngIf="gridType !== 'approved' && gridType !== 'secure'"
      (click)="multiChangeStatus(Status.active)"
      icon="check_circle"
      iconHoverColor="success"
      [disabled]="isDisableMultiActions"
      class="action-btn"
    >
      Approve
    </button>

    <button
      adxadStrokedButton
      *ngIf="gridType === 'secure'"
      icon="change_circle"
      iconHoverColor="success"
      [disabled]="true"
      class="action-btn"
    >
      Resend
    </button>

    <button
      adxadStrokedButton
      *ngIf="gridType === 'pending'"
      icon="info_outline"
      iconHoverColor="warning"
      (click)="setNeedInfoStatus()"
      [disabled]="isDisableMultiActions"
      class="action-btn"
    >
      Need info
    </button>

    <button
      adxadIconButton
      class="action-btn"
      [ngClass]="{'active': multiSelect.checkAll}"
      (click)="toggleAll()"
      [disabled]="isLoading"
    >
      <i class="material-icons">done_all</i>
    </button>
  </div>
</section>

<div class="filter-toolbar row">
  <div class="col-auto">
    <adxad-calendar
      [disabled]="isLoading"
      (changeDates)="filter.changeCalendarDates($event)"
      [from]="filter.calendar?.value?.calendar.from"
      [to]="filter.calendar?.value?.calendar.to"
    ></adxad-calendar>
  </div>

  <div class="col"></div>

  <div class="col-auto">
    <adxad-filter-actions
      [count]="filter.count"
      [notApplied]="filter.isNotApplied"
      [disabled]="isLoading"
      (openFilter)="openFilter()"
      (clearFilters)="clearFilters()"
      (applyFilter)="applyFilter()"
    ></adxad-filter-actions>
  </div>
</div>

<div
  class="grid-loader"
  *ngIf="isLoading"
>
  <adxad-loader [diameter]="40"></adxad-loader>
</div>

<section
  class="creatives"
  *ngIf="!isLoading"
>
  <div class="row">
    <div
      class="col-12 col-md-6 col-lg-4 col-xl-3"
      *ngFor="let creative of creatives"
    >
      <article class="creative">
        <div class="creative__header">
          <adxad-checkbox
            [checked]="isChecked(creative.id)"
            (changeChecked)="toggleCreative(creative, $event)"
          ></adxad-checkbox>

          <div
            class="creative__name"
            (click)="openCreative(creative)"
          >
            {{creative.name | truncate: 33}}
          </div>
        </div>

        <section
          (click)="openCreative(creative)"
          class="creative__preview"
        >
          <ng-container [ngSwitch]="creative.campaign.type">
            <ng-container *ngSwitchCase="'video'">
              <ng-container *ngTemplateOutlet="videoTpl; context: {data: creative}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'vast'">
              <ng-container *ngTemplateOutlet="vastTpl; context: {data: creative}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'html'">
              <ng-container *ngTemplateOutlet="htmlTpl; context: {data: creative}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'pops'">
              <ng-container *ngTemplateOutlet="popsTpl"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'interstitial'">
              <ng-container *ngTemplateOutlet="interstitialTpl"></ng-container>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <!--              Banner, push or native-->
              <ng-container *ngTemplateOutlet="imageTpl; context: {data: creative}"></ng-container>
            </ng-container>
          </ng-container>
        </section>

        <section class="creative__info">
          <div
            class="creative__info-block"
            *ngIf="creative.bid"
          >
            <span class="creative__info-block-label">Bid ({{ creative.bidModel | uppercase }}):</span>
            {{creative.bid}}
          </div>

          <div
            class="creative__info-block"
            *ngIf="creative.comment"
          >
            <span class="creative__info-block-label">Comment:</span>
            <span [adxadTooltip]="creative.comment.length > 20 ? creative.comment : null"> {{creative.comment | truncate: 20}} </span>
          </div>

          <div
            class="creative__info-block"
            *ngIf="creative.campaign.mediaTypeName"
          >
            <span class="creative__info-block-label">Media type:</span>
            {{creative.campaign.mediaTypeName}}
          </div>

          <div
            class="creative__info-block"
            *ngIf="creative.campaign.type === 'image' && creative.fileSize"
          >
            <span class="creative__info-block-label">File size:</span>
            {{ creative.fileSize / 1000 }} kB
          </div>

          <div
            class="creative__info-block"
            *ngIf="creative.campaign.type !== 'html'"
          >
            <span class="creative__info-block-label">Click url:</span>
            <app-cut-url
              [url]="(creative.campaign.type === 'pops' || creative.campaign.type === 'interstitial') ? creative.clickUrl : creative.campaign.clickUrl"
            ></app-cut-url>
          </div>

          <div
            class="creative__info-block creative__code"
            *ngIf="creative.html"
            (click)="openIframeCode(creative)"
          >
            <span class="creative__info-block-label">Code:</span>
            {{creative.html | truncate: 32}}
          </div>

          <div
            class="creative__info-block"
            *ngIf="creative.project.name"
          >
            <span class="creative__info-block-label">Project:</span>
            <span
              class="link"
              (click)="openProject(creative.project.id)"
            >
              {{creative.project.name | truncate: 32}}
            </span>
          </div>

          <div
            class="creative__info-block"
            *ngIf="creative.campaign.name"
          >
            <span class="creative__info-block-label">Campaign:</span>
            <span
              class="link"
              (click)="openCampaign(creative.campaign.id)"
            >
              {{creative.campaign.name | truncate: 16}}
            </span>
          </div>

          <div
            class="creative__info-block"
            *ngIf="creative.manager.email"
          >
            <span class="creative__info-block-label">Owner:</span>
            {{ creative.manager.email | truncate: 20}}
          </div>

          <div
            class="creative__info-block"
            *ngIf="creative.createdAt"
          >
            <span class="creative__info-block-label">Updated at:</span>
            {{ creative.updatedAt * 1000 | date: 'M/d/yy, H:mm' }}
          </div>
        </section>

        <footer class="creative__btns">
          <button
            adxadStrokedButton
            *ngIf="gridType !== 'rejected'"
            icon="block"
            iconHoverColor="error"
            class="creative__btn creative__btn_reject"
            (click)="multiChangeStatus(Status.rejected, [creative.id])"
          >
            Reject
          </button>

          <button
            adxadStrokedButton
            *ngIf="gridType !== 'approved' && gridType !== 'secure'"
            icon="check_circle"
            iconHoverColor="success"
            class="creative__btn creative__btn_approve"
            (click)="multiChangeStatus(Status.active, [creative.id])"
          >
            Approve
          </button>

          <button
            adxadStrokedButton
            *ngIf="gridType === 'secure'"
            icon="change_circle"
            iconHoverColor="success"
            class="creative__btn creative__btn_approve"
            [disabled]="true"
          >
            Resend
          </button>

          <button
            adxadStrokedButton
            *ngIf="gridType !== 'pending' && gridType !== 'secure' && gridType !== 'needInfo'"
            icon="refresh"
            iconHoverColor="warning"
            class="creative__btn creative__btn_approve"
            (click)="multiChangeStatus(Status.pending, [creative.id])"
          >
            Pending
          </button>
        </footer>
      </article>
    </div>
  </div>
</section>

<footer class="creatives-paginator">
  <adxad-paginator
    (changePage)="loadGrid()"
    [disabled]="isLoading"
  ></adxad-paginator>
</footer>

<!-- banner, push & native template -->
<ng-template
  #imageTpl
  let-creative="data"
>
  <img
    class="creative__img"
    [src]="creative.sourceUrl"
    alt="Creative image"
  />
</ng-template>

<!--video template-->
<ng-template
  #videoTpl
  let-creative="data"
>
  <video
    class="creative__video"
    controls
  >
    <source
      [src]="creative.sourceUrl"
      type="video/mp4"
    />
  </video>
</ng-template>

<!--  html template -->
<ng-template
  #vastTpl
  let-creative="data"
>
  <div class="creative__pops">
    <img
      alt=""
      class="creative__pops-icon"
      src="/assets/images/icons/vast-icon.svg"
    />
    VAST
  </div>
</ng-template>

<!--  html template -->
<ng-template
  #htmlTpl
  let-creative="data"
>
  <div class="creative__pops">
    <img
      alt=""
      class="creative__pops-icon"
      src="/assets/images/icons/html-icon.svg"
    />
    HTML
  </div>
</ng-template>

<!--pops template-->
<ng-template #popsTpl>
  <div class="creative__pops">
    <img
      class="creative__pops-icon"
      src="/assets/images/icons/pops-icon.svg"
      alt=""
    />
    Popunder
  </div>
</ng-template>

<!-- interstitial template -->
<ng-template #interstitialTpl>
  <div class="creative__interstitial">
    <img
      class="creative__interstitial-icon"
      src="/assets/images/icons/interstitial-icon.svg"
      alt=""
    />
    Interstitial
  </div>
</ng-template>
