<div
  class="creative-loader"
  *ngIf="isLoading || isLoadingStatus"
>
  <adxad-loader [diameter]="40"></adxad-loader>
</div>

<adxad-sidebar-modal-container
  class="creative-view"
  *ngIf="creative && !isLoading"
>
  <adxad-sidebar-modal-header>
    <adxad-sidebar-modal-close-btn (click)="close()"></adxad-sidebar-modal-close-btn>
    <h1 adxadSidebarModalTitle>{{creative.name}}</h1>
  </adxad-sidebar-modal-header>

  <adxad-sidebar-modal-body>
    <section
      adxadSidebarModalSection
      *ngIf="creative.mediaType !== 'html'"
    >
      <app-cut-url [url]="clickUrl"></app-cut-url>
    </section>

    <section
      adxadSidebarModalSection
      class="row"
    >
      <adxad-sidebar-modal-info-block
        *ngIf="creative.createdAt"
        class="col-6"
        label="Created at"
      >
        {{ creative.createdAt * 1000 | date: 'M/d/yy, H:mm' }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        *ngIf="creative.updatedAt"
        class="col-6"
        label="Updated at"
      >
        {{ creative.updatedAt * 1000 | date: 'M/d/yy, H:mm' }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-12"
        label="Status"
      >
        <adxad-entity-status [entityStatus]="creative.status"></adxad-entity-status>
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-12"
        label="Comment"
        *ngIf="creative.comment"
      >
        {{creative.comment}}
      </adxad-sidebar-modal-info-block>
    </section>

    <section
      adxadSidebarModalSection
      class="row"
    >
      <adxad-sidebar-modal-info-block
        (click)="openCampaignDetails(creative.campaign.id)"
        *ngIf="creative.campaign"
        class="col-6"
        label="Campaign"
      >
        <span class="link"> {{ creative.campaign.name }} </span>
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Project"
        *ngIf="creative.project"
      >
        {{creative.project.name}}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Owner"
        *ngIf="creative.manager.email"
      >
        <span
          class="link"
          (click)="openUserView()"
        >
          {{creative.manager.email}}
        </span>
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Team"
        *ngIf="creative.team"
      >
        {{creative.team.name}}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        *ngIf="creative.accountManager"
        class="col-6"
        label="Account manager"
      >
        {{ creative.accountManager }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        *ngIf="creative.salesManager"
        class="col-6"
        label="Sales manager"
      >
        {{ creative.salesManager }}
      </adxad-sidebar-modal-info-block>
    </section>

    <section
      adxadSidebarModalSection
      class="row"
      [hideSeparator]="creative.status !== 1 && creative.status !== 3"
    >
      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Media type"
      >
        {{creative.mediaTypeName}}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Advert format"
        *ngIf="creative.advertFormat"
      >
        {{creative.advertFormat}}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Bid"
        *ngIf="creative.bid"
      >
        {{creative.bid}}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        *ngIf="creative.creative.fileSize"
        class="col-6"
        label="File size"
      >
        {{ creative.creative.fileSize / 1000 }} kB
      </adxad-sidebar-modal-info-block>
    </section>

    <section
      adxadSidebarModalSection
      class="row"
      [hideSeparator]="true"
      *ngIf="creative.status === 1 || creative.status === 3"
    >
      <adxad-sidebar-modal-info-block
        class="col-6"
        [label]="creative.status === 1 ? 'Approved' : 'Rejected'"
        *ngIf="creative.updatedAt"
      >
        {{creative.updatedAt * 1000 | date: 'MM/dd/yyyy, hh:mm'}}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        [label]="(creative.status === 1 ? 'Approved' : 'Rejected') + ' by'"
        *ngIf="creative.manager"
      >
        {{creative.manager.name}}
      </adxad-sidebar-modal-info-block>
    </section>

    <adxad-tabs-nav *ngIf="isPreview">
      <adxad-tab-link
        [class.active]="showTab === 1"
        (click)="showTab = 1"
        >Preview</adxad-tab-link
      >
      <adxad-tab-link
        *ngIf="creative.mediaType === 'html'"
        [class.active]="showTab === 2"
        (click)="showTab = 2"
      >
        HTML
      </adxad-tab-link>
    </adxad-tabs-nav>

    <adxad-sidebar-modal-preview
      class="creative-preview"
      *ngIf="showTab === 1 && isPreview"
    >
      <!-- push -->
      <div
        class="creative-preview__push"
        *ngIf="creative.mediaType === 'push'"
      >
        <div class="creative-preview__push-header">
          <img
            [alt]="creative.creative.title"
            [src]="creative.creative.iconUrl"
            class="creative-preview__push-header__icon"
          />
          <div class="creative-preview__push-header__text">
            <div class="text">{{ (creative.creative.title | truncate: 30) || 'Creative text' }}</div>
            <div class="description">{{ (creative.creative.description | truncate: 30) || 'Creative description' }}</div>
          </div>
        </div>

        <img
          alt=""
          class="creative-preview__image"
          [src]="creative.creative.sourceUrl"
        />
      </div>

      <!-- Native -->
      <div
        class="creative-preview__native"
        *ngIf="creative.mediaType === 'native'"
      >
        <img
          alt=""
          class="creative-preview__image"
          [src]="creative.creative.sourceUrl"
        />
        <div class="creative-preview__native-title">{{ creative.creative.title | truncate:50 }}</div>
        <div class="creative-preview__native-description">{{ creative.creative.description | truncate:50 }}</div>
        <div class="creative-preview__native-brandname">{{ creative.creative.brandName | truncate:50}}</div>
      </div>

      <!-- image -->
      <img
        class="creative-preview__image"
        [src]="creative.creative.sourceUrl"
        *ngIf="creative.mediaType === 'image'"
        alt="creative preview"
      />

      <!-- video -->
      <video
        class="creative-preview__video"
        controls
        *ngIf="isVideo"
      >
        <source
          [src]="creative.creative.sourceUrl"
          type="video/mp4"
        />
      </video>

      <!-- vast -->
      <video
        *ngIf="isVast"
        class="creative-preview__video"
        controls
      >
        <source
          [src]="creative.creative.videoUrl"
          type="video/mp4"
        />
      </video>

      <!-- html -->
      <div
        class="creative-preview__iframe-wrapper"
        *ngIf="isIframe"
        [innerHTML]="creative.creative.html | escapeHtml"
      ></div>
    </adxad-sidebar-modal-preview>

    <div
      class="iframe-code"
      *ngIf="creative.mediaType === 'html' && showTab === 2"
    >
      {{creative.creative.html}}
    </div>
  </adxad-sidebar-modal-body>

  <adxad-sidebar-modal-actions>
    <ng-container [ngSwitch]="creative.status">
      <!-- approved -->
      <ng-container *ngSwitchCase="1">
        <ng-container *ngTemplateOutlet="pendingBtnTpl"></ng-container>
        <ng-container *ngTemplateOutlet="rejectBtnTpl"></ng-container>
      </ng-container>

      <!-- pending -->
      <ng-container *ngSwitchCase="2">
        <ng-container *ngTemplateOutlet="rejectBtnTpl"></ng-container>
        <ng-container *ngTemplateOutlet="approveBtnTpl"></ng-container>
      </ng-container>

      <!-- rejected -->
      <ng-container *ngSwitchCase="3">
        <ng-container *ngTemplateOutlet="pendingBtnTpl"></ng-container>
        <ng-container *ngTemplateOutlet="approveBtnTpl"></ng-container>
      </ng-container>

      <!-- To adSecure for checking -->
      <ng-container *ngSwitchCase="6">
        <ng-container *ngTemplateOutlet="rejectBtnTpl"></ng-container>
        <ng-container *ngTemplateOutlet="resendBtnTpl"></ng-container>
      </ng-container>

      <!-- need info -->
      <ng-container *ngSwitchCase="10">
        <ng-container *ngTemplateOutlet="rejectBtnTpl"></ng-container>
        <ng-container *ngTemplateOutlet="approveBtnTpl"></ng-container>
      </ng-container>
    </ng-container>
  </adxad-sidebar-modal-actions>
</adxad-sidebar-modal-container>

<ng-template #rejectBtnTpl>
  <button
    adxadSidebarModalAction
    actionType="main-red"
    (click)="changeStatus(3)"
  >
    <span class="material-icons">do_disturb_alt</span>
    Reject
  </button>
</ng-template>

<ng-template #approveBtnTpl>
  <button
    adxadSidebarModalAction
    actionType="main-blue"
    (click)="changeStatus(1)"
  >
    <span class="material-icons">check</span>
    Approve
  </button>
</ng-template>

<ng-template #pendingBtnTpl>
  <button
    adxadSidebarModalAction
    actionType="main-orange"
    (click)="changeStatus(2)"
  >
    <span class="material-icons">refresh</span>
    Pending
  </button>
</ng-template>

<ng-template #resendBtnTpl>
  <button
    adxadSidebarModalAction
    actionType="main-blue"
    [disabled]="true"
  >
    <span class="material-icons-outlined">change_circle</span>
    Resend
  </button>
</ng-template>
