<adxad-modal-close-btn (click)="close()"></adxad-modal-close-btn>

<adxad-modal-header>
  <h2 adxadModalTitle>Add comment</h2>

  <adxad-modal-content>
    <adxad-form-field class="field-group">
      <adxad-textarea
        label="Comment"
        placeholder="Enter comment"
        [formControl]="comment"
      ></adxad-textarea>
    </adxad-form-field>
  </adxad-modal-content>

  <adxad-modal-actions class="row">
    <div class="col">
      <button
        adxadStrokedButton
        (click)="close()"
        class="w100"
      >
        Cancel
      </button>
    </div>

    <div class="col">
      <adxad-submit-button
        [disabled]="isLoadingSubmit"
        (click)="submit()"
        [isLoading]="isLoadingSubmit"
      >
        Save
      </adxad-submit-button>
    </div>
  </adxad-modal-actions>
</adxad-modal-header>
